import React, { useEffect, useState } from "react";
import backgroundImg from '../../assets/images/banner/b2.jpg';
import ic1 from '../../assets/images/services/1.svg'
import ic2 from '../../assets/images/services/2.svg'
import ic3 from '../../assets/images/services/3.svg'
import ic4 from '../../assets/images/services/4.svg'
import ic5 from '../../assets/images/services/5.svg'
import ic6 from '../../assets/images/services/6.svg'
import ic7 from '../../assets/images/services/7.svg'
import ic8 from '../../assets/images/services/8.svg'
import './Card.css';


const Operations=props=>{

  const service=[
    {
      heading:`Medical Billing & Coding Services`,
      title:`Medical Billing & Coding Services`,
      desc:`Medical billing and coding services ensure accurate reimbursement and efficient management of healthcare records`,
      icon:ic1
    },
    {
      heading:`Physicans Credentialing Services`,
      title:`Physicans Credentialing Services`,
      desc:`Physician credentialing services verify and maintain healthcare providers' qualifications, ensuring regulatory compliance.`,
      icon:ic2
    },
    {
      heading:`Revenue Cycle Management`,
      title:`Revenue Cycle Management`,
      desc:`Revenue cycle management services streamline financial processes, enhancing cash flow and reducing payment delays`,
      icon:ic3
    },
    {
      heading:`Billing and Coding Audit`,
      title:`Billing and Coding Audit`,
      desc:`Our audit services ensure billing and coding accuracy, compliance, and revenue optimization for healthcare providers.`,
      icon:ic4
    },
    {
      heading:`Verification and Prior Authorization`,
      title:`Verification and Prior Authorization`,
      desc:`Verification and prior authorization services streamline insurance processes, ensuring timely approvals and minimizing claim denials`,
      icon:ic5
    },
    {
      heading:`Denial Management`,
      title:`Denial Management`,
      desc:`Denial management services identify and rectify claim rejections, optimizing revenue and reducing administrative burden for healthcare providers`,
      icon:ic6
    },
    {
      heading:`A/R Management`,
      title:`A/R Management`,
      desc:`Accounts receivable (AR) management services optimize revenue by overseeing billing, follow-up, and payment collection processes efficiently.`,
      icon:ic7
    },
    {
      heading:`Patient Billing`,
      title:`Patient Billing`,
      desc:`Patient billing services handle invoicing, statements, and payment processing, ensuring timely and accurate collection of patient balances`,
      icon:ic8
    }
  ]

    return (
        <div className="relative flex flex-col justify-center items-center my-10 p-7 md:p-10" >            
        <div className="absolute bg-secondary bg-opacity-10 w-full h-full z-[-1]"></div>
  <div className="absolute z-[-10] w-full h-full flex justify-right items-center bg-black" >
  <img src={backgroundImg} className="h-full w-full" alt="1" loading="lazy"/>
  </div>
        
        <p className="text-2xl font-bold leading-10 tracking-[2px] my-5 text-white text-center" id="services" >Leverage our services and dedicate your time to what matters most—your work.</p>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 my-3 p-5 md:px-12">
          {
            service.map(x=>{
              return (<CardFliper data={x}/>)
            })
          }
            
            
        </div>

        <div className="w-[90%] lg:w-[80%] mx-auto p-10 bg-secondary rounded-lg flex flex-col md:flex-row justify-around mt-5">
            <p className="text-white leading-10 tracking-10 text-lg font-bold">Take advantage of a 20% discount on medical billing services for the next three months!</p>
            <button className="btn" onClick={() => {
    window.location.href = `tel:3396751413`;
  }}>  Call Us
</button>
        </div>
      </div>
    )
}

const CardFliper=({data})=>{

    return (
        <div className="card2 shadow-lg">
          <div className="bg-white rounded-full p-3 mcenter">
          <img src={data.icon} className="h-[35px] w-[35px]"/>
          </div>
  
  <p className="text-lg leading-9 tracking-[1px] text-white mt-2 text-center">{data.heading}</p>
  <div className="card2__content">
    <p className="card2__title">{data.title}</p>
    <p className="card2__description">{data.desc}</p>
  </div>
</div>

    )
}

export default Operations
