 
import LogoWhite from '../../assets/images/logoWhite.png'
import Logo from '../../assets/images/logo.png'
import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { appConfig } from '../../appConfig'
import Bars from '../../assets/images/bars.svg'
import Service from '../../Service/Service'
import { Phone } from 'react-feather'



const Navbar=props=>{

    const headRoute = appConfig.headRoute

    const [viewMenu,setViewMenu]  = useState(false)
    const [categories,setCategories]=useState([])
    const [colorChange,setColorChange]=useState(false)

    const changeBackground = () => {
        console.log(window.scrollY)
        if (window.scrollY >= 66) {
            setColorChange(true)
        } else {
            setColorChange(false)
        }
      }

      useEffect(()=>{
        window.addEventListener('scroll',changeBackground)

        return()=>{
            window.removeEventListener('scroll',changeBackground)
        }
      },[])

    const url = window.location.pathname
    console.log(' %% URL : ',url)

    return(
        <div className={`bg-${props.bg || colorChange?'secondary':'transparent'} sticky top-0 z-[100] w-full  ease-in-out delay-300`}>
             
                     <div className='navTopSection h-[30px] items-center px-14 border-b border-b-white hidden lg-1:flex' >
                         <div className='flex gap-8 text-xs font-normal text-white'>
                             <p>
                                 info@healthcarembs.com
                             </p>
                             <p>
                             (339) 675-1413
                             </p>
                             <p>
                             1309 Coffeen Ave, Suite 1200 Sheridan, Wyoming 82801
                             </p>
                         </div>
                     </div>
                
        <div className="navbar  ">
       
            <div className="navbar-start px-0 lg-1:px-14 w-full lg-1:w-1/4">
                <img src={Logo} className='h-[80px]'/>
            </div>
            <div className="navbar-center hidden gap-4 lg-1:flex">
                

                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#home'} onClick={()=>{
                                const element = document.getElementById("home");
                                element.scrollIntoView({ behavior: "smooth", block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Home</label>
                            </Link>
                



                           

                            
                    
                    
                            <Link className='bg-transparent font-poppins tracking-[2px]' to={'#solution' } onClick={()=>{
                                const element = document.getElementById("solution");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Solution</label>
                            </Link>
                            <Link className='bg-transparent font-poppins tracking-[2px]' to={'#services' } onClick={()=>{
                                const element = document.getElementById("services");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Services</label>
                            </Link>
                            <Link className='bg-transparent font-poppins tracking-[2px]' to={'#process' } onClick={()=>{
                                const element = document.getElementById("process");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Our Process</label>
                            </Link>
                            <Link className='bg-transparent font-poppins tracking-[2px]' to={'#special' } onClick={()=>{
                                const element = document.getElementById("special");
                                element.scrollIntoView({ behavior: "smooth", block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Our Specialities</label>
                            </Link>
                            <Link className='bg-transparent font-poppins tracking-[2px]' to={'#whyus' } onClick={()=>{
                                const element = document.getElementById("whyus");
                                element.scrollIntoView({ behavior: "smooth",  block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Why Us</label>
                            </Link>
                            {/* <Link className='bg-transparent font-poppins tracking-[2px]' to={'#testimonial' } onClick={()=>{
                                const element = document.getElementById("testimonial");
                                element.scrollIntoView({ behavior: "smooth",  inline: "nearest" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Testimonials</label>
                            </Link> */}
                            <Link className='bg-transparent font-poppins tracking-[2px]' to={'#touch' } onClick={()=>{
                                const element = document.getElementById("touch");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Contact Us</label>
                            </Link>
                            <button className="btn mt-1" onClick={()=>{window.location.href='#touch'}}><Phone/>  Lets Connect
</button>
                
                
            </div>
            <div className="navbar-end flex lg-1:hidden">
                <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="bg-primary btn-ghost rounded-btn">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="#ffffff" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>    
                         */}
                         <img src={Bars} height={'30px'} width={'30px'}/>
                    </label>
                    <ul tabIndex={0} className="menu dropdown-content z-[100] bg-secondary p-2 shadow text-black  w-[100vw] mt-4">
                        <li>
                            <ul className="menu w-full">
                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#home'} onClick={()=>{
                                const element = document.getElementById("home");
                                element.scrollIntoView({ behavior: "smooth", block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Home</label>
                            </Link>
                                </li>
                                
                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#solution' } onClick={()=>{
                                const element = document.getElementById("solution");
                                element.scrollIntoView({ behavior: "smooth", block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Solution</label>
                            </Link>
                                </li>
                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#services' } onClick={()=>{
                                const element = document.getElementById("services");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Services</label>
                            </Link>
                                </li>
                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#process' } onClick={()=>{
                                const element = document.getElementById("process");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Our Process</label>
                            </Link>
                                </li>
                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#special' } onClick={()=>{
                                const element = document.getElementById("special");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Our Specialities</label>
                            </Link>
                                </li>
                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#whyus' } onClick={()=>{
                                const element = document.getElementById("whyus");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Why Us</label>
                            </Link>
                                </li>
                                {/* <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#testimonial' } onClick={()=>{
                                const element = document.getElementById("testimonial");
                                element.scrollIntoView({ behavior: "smooth",  inline: "nearest" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Testimonials</label>
                            </Link>
                                </li> */}
                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'#touch' } onClick={()=>{
                                const element = document.getElementById("touch");
                                element.scrollIntoView({ behavior: "smooth",block: "center" });

                            }}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Contact Us</label>
                            </Link>
                                </li>
                                <li>
                                <button className="btn mt-1" onClick={()=>{window.location.href='#touch'}}><Phone/>  Lets Connect
</button>
                                </li>
                            </ul>
                        </li> 
                        <li>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
        // <div className='absolute z-10 top-0 left-0 w-full font-poppins'>
            
        //     <div 
        //     className={`
        //         nav 
        //         ${
        //             url!='/'?
        //             'bg-white lg-1:bg-transparent':'bg-transparent'
        //         }
        //     `}
        //     >

        //         {
        //             url!='/'?
        //             <div className='navTopSection h-[40px] items-center px-14 border-b border-b-white hidden lg-1:flex'>
        //                 <div className='flex gap-8 text-xs font-normal text-white'>
        //                     <p>
        //                         valiance@qodeinteractive.com
        //                     </p>
        //                     <p>
        //                         073-082-327-84
        //                     </p>
        //                     <p>
        //                         Maria-Theresien-Platz, 1010 Wien
        //                     </p>
        //                 </div>
        //             </div>:''
        //         }
        //         <div className='logoSection py-9 px-14 flex gap-12'>
                    
        //             {
        //                 url=='/'?
        //                 <div className='logo'>
                            
        //                     <img src={LogoDark} className='w-[200px]'/>
                            
        //                 </div>:
        //                 <div className='logo'>
        //                     <img src={LogoWhite} className='w-[200px] hidden lg-1:block'/>
        //                     <img src={LogoDark} className='w-[200px] block lg-1:hidden'/>
        //                 </div>
        //             }
        //             <div>

        //                 <div  className='hidden lg-1:flex flex-column lg-1:flex-row gap-11'>
        //                     <div className="dropdown dropdown-hover">
        //                         <label tabIndex={0} className="cursor-pointer m-1">Home</label>
        //                         <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-black text-white w-52">
        //                             <li >
        //                                 <Link className='font-poppins tracking-[2px]'>
        //                                     MAIN HOME
        //                                 </Link>
        //                             </li>
        //                             <li >
        //                                 <Link className='font-poppins tracking-[2px]'>
        //                                     CONSULTING HOME
        //                                 </Link>
        //                             </li>
        //                         </ul>
        //                     </div>

        //                     <div className="dropdown dropdown-hover">
        //                         <label tabIndex={0} className="cursor-pointer m-1">Pages</label>
        //                         <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-black text-white w-52">
        //                             <li >
        //                                 <Link className='bg-transparent font-poppins tracking-[2px]'>
        //                                     ABOUT US 
        //                                 </Link>
        //                             </li>
        //                             <li >
        //                                 <Link 
        //                                 className='bg-transparent font-poppins tracking-[2px]'
        //                                 to={`${headRoute}/contact`}
        //                                 >
        //                                     CONTACT US
        //                                 </Link>
        //                             </li>
        //                         </ul>
        //                     </div>

        //                 </div>


        //             </div>

        //         </div>
        //     </div>
           
        // </div>
    )
}
export default Navbar