import React from "react";
import Logo from '../../assets/images/logo.png'
import { Link } from "react-router-dom";
import { appConfig } from "../../appConfig";
import { footerData } from "./footerData";
import ReactWhatsappButton from "react-whatsapp-button";
import { Facebook, Instagram, Linkedin, Youtube } from "react-feather";
const Footer=props=>{

    const headRoute = appConfig.headRoute

    return(
        <div className="spacingDiv bg-secondary flex  min-h-[100vh] md:xl:min-h-[60vh] 2xl:min-h-[40vh] lg-1:justify-center lg-1:items-center">
            <div className="w-11/12">
                
                <div className="flex flex-wrap justify-between flex-col lg-1:flex-row">
                <div className="mb-10 max-md:py-20 md:basis-3/7">
                    <img src={Logo} className="h-[170px] mb-4"/>
                    <p className="text-white text-sm lg-1:text-md w-full">
                    The content within this website is for general information solely. <br/>It does not constitute medical, health, legal, or financial advice. <br/>Consultation with appropriate professionals is recommended.
                    </p>
                    {/* <ReactWhatsappButton
        countryCode="44"
        phoneNumber="7401490100"
      /> */}
                </div>
                    {
                        footerData.map(x=>{
                            return(
                                <ul 
                                key={x.id}
                                className="max-md:mb-10 md:basis-2/7"
                                >
                                    <li className="text-gray-light mb-4 tracking-[3px]">
                                        {x.linkName}
                                    </li>
                                    {
                                        x.pages.map((page,index)=>{

                                            return(
                                                <li
                                                key={index}
                                                className=" text-white font-light mb-2 text-sm max-md:font-medium lg-1:text-[18px]"
                                                >
                                                    {x.link?<a href={x.link[index]} className="max-w-[100px]">{page}</a>:<a href="#">{page}</a>}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            )
                        })
                    }

                    <div className=" md:basis-2/7 text-white font-light mb-2 text-sm max-md:font-medium lg-1:text-[18px]">
                        <p className="text-gray-light tracking-[3px] mb-2">Location:</p>
                        <p className="mb-5">1309 Coffeen Ave, Suite 1200<br/>
Sheridan, Wyoming 82801</p>

<p className="text-gray-light tracking-[3px] my-2">Main Line:</p>
                        <p>(339) 675-1413</p>

                        <div className="flex gap-4 my-7 md:my-10">
                            <Facebook/>
                            <Linkedin/>
                            <Instagram/>
                            <Youtube/>

                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    )
}
export default Footer